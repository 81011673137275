<script>
import ProgressIndicator from "@/components/UI/ProgressIndicator";
import CompetitorBillet from "@/views/CompetitorsFinderPage/components/CompetitorBillet";
import LanguageSelector from "@/views/Common/LanguageSelector/LanguageSelector.vue";
import CreativesAll from "@/views/CompetitorsFinderPage/Competitors/Creatives/All.vue";
import CreativesIcons from "@/views/CompetitorsFinderPage/Competitors/Creatives/Icons.vue";
import CreativesScreenshots from "@/views/CompetitorsFinderPage/Competitors/Creatives/Screenshots.vue";
import Competitors_select_modal from "@/views/CompetitorsFinderPage/Competitors/competitors_select_modal.vue";

export default {
  name: "competitors_tab_creatives",
  components: {
    Competitors_select_modal,
    CreativesScreenshots,
    CreativesIcons,
    CreativesAll,
    LanguageSelector,
    'progress-indicator': ProgressIndicator,
    'competitor-billet': CompetitorBillet,
  },
  props: {
    currentApp: {
      type: Object,
      default: null,
    },
    currentCountry: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loaderText: 'Loading creatives...',
      loading: false,
      firstLoaded: false,
      competitorsModalIsOpen: false,
      activeTab: 'creatives-all',
      creativesState: {
        competitors: [],
        locale: null,
        device: 'iphone',
        small: true,
      },
      countryLocalesConfig: {
        border: 'none',
        boxShadow: '0 8px 38px 0 rgba(184, 186, 229, 0.16)',
        height: '36px',
      }
    }
  },
  mounted() {
  },
  methods: {
    changedLocale(locale) {
      this.creativesState.locale = locale;
    },
    selectedCompetitors(newObj) {
      this.firstLoaded = true;
      this.competitorsObj = newObj;
      this.creativesState.competitors = this.competitorsObj.getCompetitorsCheckedOnlyInitial();
      this.competitorsModalIsOpen = false
    },
    updateCompetitors(competitor) {
      let competitorObj = this.competitorsObj.getById(competitor.id);
      if (competitorObj) {
        competitorObj.setState(competitor.type);
        if (competitorObj.isCompetitor()) {
          this.competitorsObj.restore(competitorObj);
        } else {
          this.competitorsObj.remove(competitorObj);
        }
      }

      this.$emit('competitors-count-changed', this.competitorsObj?.total);

      this.creativesState.competitors = this.competitorsObj.getCompetitorsCheckedOnly();
    },
    competitorsModelOpen() {
      this.$refs.competitors_select_modal.openModal();
    },
  },
}
</script>

<template>
  <div>
    <div class="creatives-nav" v-show="firstLoaded">
      <div class="common-btn-purple-transparent competitors_selector" @click="competitorsModelOpen">Select Competitors ({{this.creativesState.competitors.length}}/{{ this.competitorsObj?.getTotalCount() }})</div>
      <div class="language-selector">
        <language-selector
            :current-app="currentApp"
            :current-country="currentCountry"
            :country-locales-config="countryLocalesConfig"
            :current-keyword-country-name="currentCountry.name"
            @locale-changed="changedLocale"
            placeholder="Choose locale"
        ></language-selector>
      </div>
      <div class="common-btn-group-purple-transparent">
        <div :class="{'not-active' : activeTab !== 'creatives-all'}" @click="activeTab = 'creatives-all'"><span>All</span></div>
        <div :class="{'not-active' : activeTab !== 'creatives-screenshots'}" @click="activeTab = 'creatives-screenshots'"><span>Screenshots</span></div>
        <div :class="{'not-active' : activeTab !== 'creatives-icons'}" @click="activeTab = 'creatives-icons'"><span>Icons</span></div>
      </div>
      <div class="common-btn-group-purple-transparent" v-if="activeTab !== 'creatives-icons' && currentApp.store.key === 'APP_STORE'">
        <div :class="{'not-active': creativesState.device !== 'iphone'}" @click="creativesState.device='iphone'"><span>IPhone</span></div>
        <div :class="{'not-active': creativesState.device !== 'ipad'}" @click="creativesState.device='ipad'"><span>IPad</span></div>
      </div>
      <div class="common-btn-group-purple-transparent" v-if="activeTab !== 'creatives-icons'">
        <div :class="{'not-active': !creativesState.small}" @click="creativesState.small=true"><span>Small</span></div>
        <div :class="{'not-active': creativesState.small}" @click="creativesState.small=false"><span>Big</span></div>
      </div>
    </div>
    <div class="progress-wrap" v-if="loading">
      <progress-indicator>{{ loaderText }}</progress-indicator>
    </div>
    <template v-else>
      <div>
          <component
              :key="currentApp.id + '|' + currentCountry.id + activeTab + this.creativesState.locale?.code"
              :current-app="currentApp"
              :current-country="currentCountry"
              :creatives-state="creativesState"
              :is="activeTab"
              @competitor-count-changed="updateCompetitors"
          ></component>
      </div>
    </template>

    <competitors_select_modal
        ref="competitors_select_modal"
        :current-app="currentApp"
        :current-country="currentCountry"
        :key="currentApp.id + '|' + currentCountry.id"
        @save-and-continue="selectedCompetitors"/>
  </div>
</template>

<style scoped lang="scss">
.creatives-nav {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}

.creatives-nav > :nth-child(4) > div,:nth-child(5) > div {
  min-width: 74px;
  padding-left: 0;
  padding-right: 0;
}

.creatives-nav > div {
  margin-bottom: 10px;
}

.common-btn-purple-transparent, .language-selector, .common-btn-group-purple-transparent {
  margin-right: 15px;
}
.language-selector {
  min-width: 280px;
}

@media (max-width: 767px) {
  .competitors_selector {
    display: none;
  }
  .creatives-nav {
    justify-content: center;
  }
}
</style>