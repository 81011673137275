<script>

import AppImage from "@/components/UI/ImageLazyLoad/index.vue";
import {cropImage} from "@/utils/utils";
import DetailsModal from "@/components/AppDetailsComponent/DetailsModal/index.vue";

export default {
  name: "CreativesIcons",
  methods: {
    cropImage,
    openModel(item) {
      this.clickedItem = item.mutateToModalAppFormat();
      this.showAppDetailsModal = true;
    },
  },
  components: {DetailsModal, AppImage},
  props: {
    creativesState: {
      type: Object,
      default: null,
    },
  },
  emits: ['competitor-count-changed'],
  data() {
    return {
      showAppDetailsModal: false,
      clickedItem: {},
    }
  },
}
</script>

<template>
  <div class="icons-container">
    <div v-for="competitor in this.creativesState.competitors" class="icon-container">
      <div class="icon-logo">
        <app-image
            :width="200"
            :height="200"
            :src="cropImage(competitor.logo, '200x200', competitor.store)"
        />
      </div>
      <div class="icon-title">
        <a :href="competitor.storeUrl" target="_blank">
          <svg-icon v-once icon="link-icon" class="link-icon common-app-store-link"/>
        </a>
        <span class="common-app-title" @click="openModel(competitor)">{{ competitor.title }}</span>
      </div>
    </div>
  </div>
  <details-modal v-if="showAppDetailsModal"
                 :opened="showAppDetailsModal"
                 :app-data="clickedItem"
                 @competitor-changed="this.$emit('competitor-count-changed', $event)"
                 @modal-closed="showAppDetailsModal = !showAppDetailsModal"/>
</template>

<style scoped lang="scss">
.icons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -15px;
}
.icon-container {
  margin: 17px 15px 0 15px;
  max-width: 200px;
}
.icon-title {
  margin-top: 5px;
  color: var(--neutral-800);
}
.icon-title a {
  margin-right: 5px;
}
.icon-logo img {
  border-radius: 8px;
  box-shadow: 0px 4px 8px 0px #A0BEDD4D;
}
.common-app-title {
  cursor: pointer;
}

@media (max-width: 459px) {
  .icons-container {
    justify-content: center;
  }
}
</style>