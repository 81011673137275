<script>
import CustomInlineSearch from "@/components/Forms/CustomInlineSearch/index.vue";
import {getLocaleCountryCode} from "@/configs/locales-flags";
import {httpRequest} from "@/api";

export default {
  name: "LanguageSelector",
  components: {CustomInlineSearch},
  props: {
    currentApp: {
      type: Object,
      default: null,
    },
    currentCountry: {
      type: Object,
      default: null,
    },
    currentKeywordCountryName: {
      type: String,
      default: null,
    },
    countryLocalesConfig: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Choose language',
    }
  },
  emits: [
    'locale-changed',
  ],
  mounted() {
    this.fetchCountryLocales();
  },
  data() {
    return {
      countryLocaleSearchInputValue: '',
      expandAddedModel: false,
      countriesLocalesObject: {},
      currentCountriesLocaleSelected: {}
    }
  },
  computed: {
    countriesLocalesComputed() {
      const tempArray = {...this.countriesLocalesObject};
      const loweredInput = this.countryLocaleSearchInputValue?.toLowerCase();
      return {
        popular: tempArray?.popular?.filter(locale => locale?.name?.toLowerCase()?.includes(loweredInput)),
        other: tempArray?.other?.filter(locale => locale?.name?.toLowerCase()?.includes(loweredInput)),
      }
    }
  },
  methods: {
    getLocaleCountryCode,
    countriesLocalesInputChanged(value) {
      this.countryLocaleSearchInputValue = value;
      this.expandAddedModel = false;
    },
    async fetchCountryLocales() {
      const localesData = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.user.COUNTRY_LOCALES + `/${this.currentApp?.store?.key}/${this.currentCountry?.code}`);
      this.countriesLocalesObject = {
        default: localesData?.default,
        popular: this.setLocales(localesData?.popular),
        other: this.setLocales(localesData?.other),
      };

      this.setDefaultLocale();

      this.$emit('locale-changed', this.currentCountriesLocaleSelected);
    },
    setDefaultLocale() {
      this.currentCountriesLocaleSelected.code = this.countriesLocalesObject?.default;
      this.currentCountriesLocaleSelected.name = getLocaleCountryCode(this.countriesLocalesObject?.default)?.localeName;
    },
    setLocales(obj) {
      let localesArr = [];
      for (const key in obj) {
        localesArr.push({
          code: key,
          name: obj[key],
        })
      }
      return localesArr;
    },
    async countriesLocalesClicked(result) {
      this.setResult(result);

      this.$refs.countriesLocalesInput.isOpened = false;

      this.$emit('locale-changed', result);
    },
    setResult(result) {
      this.countryLocaleSearchInputValue = '';
      this.currentCountriesLocaleSelected.code = result.code;
      this.currentCountriesLocaleSelected.name = result.name;
    }
  },
}
</script>

<template>
  <div class="search-locales-block">
    <custom-inline-search :placeholder="this.placeholder"
                          @search-input-changed="countriesLocalesInputChanged"
                          ref="countriesLocalesInput"
                          :with-result-slots="true"
                          :min-input-length="0"
                          :show-caret="true"
                          :full-width-content="true"
                          results-max-height="256px"
                          :initial-data="currentCountriesLocaleSelected?.name"
                          :config="countryLocalesConfig"
                          :search-results="countriesLocalesObject">

      <template v-slot:heading-info>
        <dynamic-image classes="country-flag"
                       :width="18"
                       :height="14"
                       :size="32"
                       :country-code="getLocaleCountryCode(currentCountriesLocaleSelected?.code)?.countryCode"/>
        {{ currentCountriesLocaleSelected?.name }}
      </template>

      <template v-slot:results>
        <template v-if="countriesLocalesComputed?.popular?.length > 0 || countriesLocalesComputed?.other?.length > 0">
          <div class="results-group"
               v-if="countriesLocalesComputed?.popular?.length > 0">
            <div class="group-title">Popular in {{ currentKeywordCountryName }}</div>
            <div v-for="result in countriesLocalesComputed?.popular"
                 @click="countriesLocalesClicked(result)"
                 class="result-item">
              <template v-if="getLocaleCountryCode(result?.code)?.countryCode">
                <dynamic-image classes="country-flag"
                               :width="18"
                               :height="14"
                               :size="32"
                               :country-code="getLocaleCountryCode(result?.code)?.countryCode"/>
              </template>
              <span class="item-name">{{ result?.name }}</span>
            </div>
          </div>

          <div class="results-group"
               v-if="countriesLocalesComputed?.other?.length > 0">
            <div class="group-title">Other</div>
            <div v-for="result in countriesLocalesComputed?.other"
                 @click="countriesLocalesClicked(result)"
                 class="result-item">
              <template v-if="getLocaleCountryCode(result?.code)?.countryCode">
                <dynamic-image classes="country-flag"
                               :width="18"
                               :height="14"
                               :size="32"
                               :country-code="getLocaleCountryCode(result?.code)?.countryCode"/>
              </template>
              <span class="item-name">{{ result?.name }}</span>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="results-group">
            <div class="text">No data found</div>
          </div>
        </template>
      </template>
    </custom-inline-search>
  </div>
</template>

<style scoped lang="scss">
.search-locales-block {

  @media only screen and (max-width: 767px) {
    margin: 0;
    width: 100%;
  }

  .results-group {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .group-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
      color: var(--title-color);
      margin-bottom: 8px;
    }
  }

  .result-item {
    margin-bottom: 8px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    .item-name {
      font-size: 16px;
      line-height: 1.5;
      color: var(--neutral-800);
      transition: all .3s;
      opacity: 1;
    }
  }

  .result-item:hover {
    .item-name {
      opacity: 0.68;
    }
  }
}
</style>